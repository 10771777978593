.progressContainer {
  margin-top: 20px;
}
.progress {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  background: transparent;
  border: 1px solid $borderColor;
  padding: 15px;
  height: 80px;

  &__icon {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex-basis: 100%;

    &__1 {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 14px;
      p {
        margin: 0;
        padding-left: 10px;
      }
    }

    &__2 {
      height: 4px;
      margin-bottom: 4px;
      position: relative;
      width: 100%;
      height: 2px;
      background: #d7d7d7;

      &__bar {
        z-index: 1;
        position: absolute;
        height: 4px;
        left: 0;
        top: 0;
        background: $primary !important;
      }
    }
  }
}

.file-drop {
  width: 100%;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 3px rgba(51, 46, 58, 0.1));
  margin-top: auto;
  border: 1px dashed $textLight;
  padding: 50px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
  & .file-drop-target {
    height: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;

    &.file-drop-dragging-over-target {
      opacity: 0.3;
    }

    input[type='file'] {
      width: 0;
      height: 0;
    }
    > img {
      margin: 0 0 20px;
      @media (max-width: 480px) {
        max-width: 70px;
      }
    }
  }

  .placeholder {
    font-size: 14px;
    letter-spacing: 3px;
    line-height: 26px;
    color: #3d4852;
    font-weight: 400;
    font-family: 'Montserrat';
    text-align: center;

    span {
      color: #688ee8;
      cursor: pointer;
    }
  }
}
