.login_wrapper {
  height: 100vh;
  width: 100%;
  background: url(../../Images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 110px 20px;
  overflow: auto;

  .login_box {
    background: $bgWhite;
    box-shadow: -5px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 1100px;
    max-height: 700px;
    margin: 0 auto;
    .login_img {
      width: 40%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .login_form {
      width: 60%;
      padding: 100px 75px 20px;
      background: $bgLightGray;
      .login_logo {
        margin: 0 0 50px;
      }
      h1 {
        margin: 0 0 35px;
        position: relative;
        &:before {
          width: 75px;
          height: 4px;
          border-radius: 10px;
          background: $primary;
          content: '';
          position: absolute;
          left: 0;
          bottom: -10px;
        }
      }
    }
  }
}

.otp_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  .input_wrap {
    width: 50px;
    height: 50px;
    text-align: center;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.resend_code {
  text-align: center;
  p {
    span {
      color: $primary;
      cursor: pointer;
      &:hover {
        color: $secondary;
      }
    }
  }
}

@media (max-width: 991px) {
  .login_wrapper .login_box .login_form {
    padding: 50px 40px 20px;
  }
}

@media (max-width: 767px) {
  .login_wrapper .login_box .login_img {
    display: none;
  }
  .login_wrapper .login_box .login_form {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .login_wrapper .login_box .login_form {
    padding: 50px 20px 30px;
  }
  .login_wrapper .login_box .login_form .login_logo {
    margin: 0 0 30px;
  }
  .login_wrapper .login_box .login_form .login_logo img {
    max-width: 160px;
  }
}

@media (max-width: 480px) {
  .login_wrapper {
    padding: 80px 15px;
  }
}

@media (max-width: 350px) {
  .login_form .forgot_wrap,
  .login_form .checkbox_wrap {
    font-size: 12px;
  }
}
