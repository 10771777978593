.form_group {
  > label {
    margin: 0 0 10px;
    position: relative;
    span.text-danger {
      position: absolute;
      right: -12px;
    }
  }
  .input_wrap {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding: 15px;
    &:focus,
    &:hover {
      box-shadow: none;
      border-color: $primary;
    }
    &.small {
      padding: 10px 15px;
    }
    &.search_wrap {
      padding-left: 40px;
      background-image: url(../../Images/search.svg);
      background-repeat: no-repeat;
      background-position: left 14px center;
      background-size: 17px;
    }
  }
  &.checkbox_wrap {
    > label {
      margin: 0 0 0;
    }
  }
}
.date_range_wrapper {
  position: relative;
  .date_range_select {
    background-image: url(../../Images/calender.svg);
    background-repeat: no-repeat;
    background-position: right 14px center;
    background-size: 17px;
    width: 100%;
    font-size: 14px;
    padding: 10px;
    background-color: #fff;
    padding: 11px 15px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    color: #495057;
    min-width: 230px;
    cursor: pointer;
    span.empty_date_picker {
      color: #ababab;
    }
  }
}
.date_range_wrap {
  position: relative;
  .rdrDateRangePickerWrapper {
    .rdrDefinedRangesWrapper {
      width: 185px;
    }
    .rdrCalendarWrapper {
      .rdrMonths {
        .rdrMonth {
          width: 247px;
        }
      }
    }
  }
  .btn_transperant {
    color: $textPrimary;
    font-weight: 600;
    position: absolute;
    left: 80px;
    bottom: 15px;
    &:hover {
      color: $textPrimary;
    }
  }
}
.form_group {
  .p-inputtext {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    background-color: $bgWhite;
    &:hover,
    &:focus {
      border-color: $primary;
      box-shadow: none;
    }
    &.green_input {
      font-size: 15px;
      color: $green;
      // font-weight: 600;
      &::placeholder {
        font-size: 14px;
      }
    }
  }
  .p-inputtextarea {
    &.p-disabled {
      pointer-events: inherit;
    }
  }
}
.checkbox_wrap {
  display: flex;
  align-items: center;
  .p-checkbox {
    width: 20px;
    height: 20px;
    .p-checkbox-box {
      border: 1px solid #d7d7d7;
      border-radius: 2px;
      width: 20px;
      height: 20px;
      &.p-highlight {
        background: $primary;
        border-color: $primary;
        &:hover {
          border-color: $primary;
          background: $primary;
        }
      }
      &.p-focus {
        box-shadow: none;
        border-color: $primary;
      }
      &:hover {
        border-color: $primary;
      }
    }
  }
  label {
    margin-left: 10px !important;
    line-height: 100%;
    @media (max-width: 400px) {
      margin-left: 5px !important;
    }
  }
  &.with_input {
    margin-top: 35px;
  }
  &.large {
    label {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

.btn_primary {
  border-radius: 4px;
  color: $textWhite;
  background: $primary !important;
  border-color: $primary !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding: 10px 20px;
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background: #4e4399 !important;
    border-color: #4e4399 !important;
    box-shadow: none;
    color: $textWhite;
  }
  img {
    margin-right: 7px;
    filter: invert(1) brightness(22);
  }
  &:disabled {
    opacity: 0.7 !important;
    color: $bgWhite !important;
    pointer-events: inherit;
    cursor: not-allowed !important;
  }
}

.btn_border {
  border-radius: 4px;
  color: $textWhite;
  background: transparent !important;
  border: 1px solid $borderColor !important;
  color: $textDark;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding: 9px 20px;
  img {
    margin-right: 7px;
    filter: grayscale(1);
  }
  .bank-qrcode {
    filter: initial;
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &:focus-visible:not([disabled]) {
    background: $primary !important;
    border-color: $primary !important;
    box-shadow: none !important;
    color: $textWhite;
    img {
      filter: invert(1) brightness(22);
    }
  }
  &:disabled {
    opacity: 0.7 !important;
    pointer-events: inherit;
    cursor: not-allowed !important;
  }
  &.filter_btn {
    position: relative;
    img {
      margin-right: 20px;
      @media (max-width: 767px) {
        margin-right: 10px;
      }
    }
    &.applied {
      background: #eceaff !important;
      &:before {
        content: '';
        position: absolute;
        right: -5px;
        top: -5px;
        width: 13px;
        height: 13px;
        background: #322972;
        border-radius: 10px;
      }
      &:hover,
      &:focus {
        background: $primary !important;
      }
    }
  }
  &.icon_btn {
    padding: 9px;
    img {
      margin-right: 0;
    }
  }
  &.dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

.p-dialog-mask {
  z-index: 10000 !important;
}

.attachment_btn_border {
  background: $bgPrimaryLight !important;
  border: 1px dashed $primary !important;
}

.btn_transperant {
  background: transparent;
  border: 0;
  padding: 0;
  img {
    filter: grayscale(1);
  }
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none;
    img {
      filter: inherit;
    }
  }
  &:disabled {
    opacity: 0.7 !important;
    pointer-events: inherit;
    cursor: not-allowed !important;
    border: 0 !important;
  }
}

.main_Wrapper {
  height: calc(100svh - 115px);
  overflow: hidden auto;
  padding: 15px;
}

.p-overlaypanel {
  width: 100%;
  max-width: 680px;
  max-height: 500px;
  overflow: auto;
  .p-overlaypanel-content {
    padding: 0;
    .overlay_top_wrap {
      padding: 15px;
      border-bottom: 1px solid $borderColor;
      h3 {
        font-weight: 500;
        margin: 0;
      }
    }
    .overlay_body {
      padding: 15px;
    }
  }
  .p-overlaypanel-close {
    top: 15px;
    right: 15px;
    background: transparent;
    color: $textDark;
    border: 1px solid $textDark;
    width: 20px;
    height: 20px;
    svg {
      width: 10px;
      height: 10px;
    }
    &:hover,
    &:focus {
      background: transparent !important;
      color: $textDark !important;
      box-shadow: none;
    }
  }
}

// select dropdown css

.p-dropdown {
  width: 100%;
  &.p-focus {
    box-shadow: none !important;
    border-color: $primary !important;
  }
  &.p-inputwrapper-focus {
    border-color: $primary;
  }
  &:not(.p-disabled):hover {
    border-color: $primary;
  }
  .p-dropdown-label {
    font-size: 13px;
    padding: 11px 15px !important;
    color: #333;
  }
  .p-dropdown-trigger {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      background: url(../../Images/select-arrow.svg);
      width: 20px;
      height: 20px;
      transform: translate(-50%, -50%);
    }
    &[aria-expanded='true'] {
      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .p-dropdown-trigger-icon {
      display: none;
    }
  }
}
.p-dropdown-panel {
  box-shadow: 10px 12px 32px 0 rgb(0 0 0 / 10%);
  border: 1px solid $borderColor;
  margin-top: 5px;
  z-index: 10000 !important;
  .p-dropdown-items {
    padding: 0;
    .p-dropdown-item {
      padding: 11px 9px;
      font-size: 14px;
      &.p-highlight {
        color: $primary;
        background: $bgPrimaryLight;
      }
      &:not(.p-highlight):not(.p-disabled):hover {
        color: $primary;
        background: #f6f5ff;
      }
      &.p-disabled {
        color: $primary;
        background: $bgPrimaryLight;
        cursor: not-allowed !important;
        pointer-events: auto;
      }
    }
  }
  .p-dropdown-header {
    padding: 15px 10px;
    background: $bgWhite;
    .p-dropdown-filter {
      padding: 6px 15px 6px 40px;
      &:focus {
        box-shadow: none;
        border-color: $primary;
      }
    }
    .p-dropdown-filter-icon {
      right: inherit;
      left: 15px;
    }
  }
}

.overlay_select_filter_row {
  display: flex;
  align-items: center;
  .filter_row {
    width: calc(100% - 40px);
    .row {
      gap: 5px 0;
    }
  }
  .remove_row {
    margin-left: 10px;
    width: 30px;
    text-align: center;
  }
}

input[type='color' i] {
  padding: 0;
  height: 50px;
  width: 50px;
}
input[type='color' i]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
  padding: 0;
}
input[type='color' i]::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}

.modal_Wrapper {
  width: 100%;
  &.modal_extra_small {
    max-width: 300px;
  }
  &.modal_small {
    max-width: 500px;
    padding: 0 20px;
    box-shadow: none;
  }
  &.modal_medium {
    max-width: 920px;
    padding: 0 20px;
    box-shadow: none;
  }
  &.modal_large {
    max-width: 1220px;
    padding: 0 20px;
    box-shadow: none;
  }
  &.model_extra_large {
    max-width: 1400px;
    padding: 0 20px;
    box-shadow: none;
  }
  .p-dialog-header {
    padding: 15px;
    border-bottom: 1px solid $borderColor;
    .p-dialog-title {
      font-size: 18px;
      font-weight: 600;
      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
    .p-dialog-header-icons {
      .p-dialog-header-icon {
        background: transparent;
        color: #343434;
        border: 1px solid #343434;
        width: 20px;
        height: 20px;
        box-shadow: none !important;
        svg {
          width: 10px;
          height: 10px;
        }
        &:hover {
          background: transparent;
          border: 1px solid #343434;
        }
      }
    }
  }
  .p-dialog-content {
    padding: 20px 15px;
    position: relative;
    .modal_export_button {
      width: 20px;
      position: absolute;
      right: 19px;
      top: 10px;
      z-index: 999;
    }
  }
}
.overlay_bottom_wrap {
  padding: 15px;
  border-top: 1px solid $borderColor;
  .saved_filter_wrap {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        margin: 0 10px 5px 0;
        .p-chip {
          background: $bgWhite;
          border: 1px solid $borderColor;
          padding: 0 10px 0 0;
          &.active {
            background-color: rgb(50, 41, 114);
            color: rgb(255, 255, 255);
          }
          .edit_icon {
            border-radius: 50%;
            background: $bgPrimaryLight;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -1px;
            margin-top: -1px;
            img {
              width: 15px;
              height: 15px;
              margin: 0;
            }
          }
          .p-inputtext {
            background: transparent;
            border: 0;
            padding: 7px 10px;
            box-shadow: none !important;
            font-size: 14px;
            width: 145px;
            text-overflow: ellipsis;
          }
          .btn_transperant {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 18px;
              height: 18px;
              margin: 0;
              filter: grayscale(1);
            }
          }
          &.active {
            .p-inputtext {
              color: $textWhite;
            }
            .btn_transperant {
              img {
                filter: invert(1) brightness(100);
              }
            }
          }
        }
      }
    }
  }
}

.export_dropdown {
  .dropdown-menu {
    max-width: 100px;
    min-width: inherit;
  }
}

.text-loader {
  font-size: 18px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.upload_file_custom {
  input {
    display: none;
  }
  label {
    text-align: center;
    background: $bgPrimaryLight;
    border: 1px dashed $primary;
    border-radius: 10px;
    width: 100%;
    padding: 20px 30px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    @media (max-width: 480px) {
      font-size: 14px;
    }
    img {
      display: block;
      margin: 0 auto 10px;
    }
    iframe {
      display: block;
      margin: 0 auto 10px;
      width: 60px;
      height: 60px;
    }
    .upload_text {
      max-width: 230px;
      margin: 0 auto;
    }
  }
  .uploaded_img_main {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    .uploaded_image_wrapper {
      display: flex;
      align-items: center;
      margin: 0;
      width: calc(33.333% - 7px);
      position: relative;
      flex-direction: column;
      z-index: 9;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        z-index: 1;
      }
      .uploaded_img,
      .uploaded_doc {
        width: 100%;
        height: 60px;
        object-fit: cover;
        margin-right: 0;
        margin-bottom: 0;
        background: #ebe9ff;
        border-radius: 5px;
        border: 1px solid #322972;
      }
      .download_wrap {
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        column-gap: 5px;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
        .btn_transperant {
          padding: 0;
          border: 0 !important;
          img {
            filter: grayscale(1) invert(1);
          }
        }
      }
      &:hover {
        .download_wrap {
          opacity: 1;
          visibility: visible;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.small_dropzone_wrapper {
  .upload_file_custom {
    label {
      .btn_border {
        img {
          width: 20px;
        }
      }
    }
  }
}

.tab_main_wrapper {
  .p-tabview {
    .p-tabview-nav-container {
      .p-tabview-nav {
        border: 0;
        background: transparent;
        .p-unselectable-text {
          margin: 0 5px;
          .p-tabview-nav-link {
            padding: 0 5px 8px;
            font-weight: 500;
            background-color: transparent;
            &:focus {
              box-shadow: none;
            }
            &:hover {
              background-color: transparent;
            }
          }
          &.p-highlight {
            .p-tabview-nav-link {
              color: $primary;
              border-color: $primary;
            }
          }
        }
        .p-tabview-ink-bar {
          background-color: $primary;
        }
      }
    }
    .p-tabview-panels {
      padding: 15px 0 0;
      background: transparent;
    }
  }
}

.date_select_wrapper {
  .p-calendar {
    .p-datepicker-trigger {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 10px;
        top: 8px;
        width: 20px;
        height: 20px;
        background: url(../../Images/calender.svg);
        background-repeat: no-repeat;
        background-size: 20px;
      }
      svg {
        display: none;
      }
    }
  }
  &.only_icon {
    .p-calendar {
      width: 40px;
      .p-inputtext {
        width: 0;
        opacity: 0;
        visibility: hidden;
        padding: 0;
      }
    }
  }
}

.p-datepicker {
  z-index: 10000 !important;
  padding: 0;
  min-width: 280px !important;
  // left: 800px !important; need to add in media query
  .p-datepicker-group {
    .p-datepicker-header {
      background: $bgPrimaryLight;
      .p-datepicker-title {
        .p-datepicker-month {
          &:hover {
            color: $primary;
          }
        }
        .p-datepicker-year {
          &:hover {
            color: $primary;
          }
        }
      }
      .p-datepicker-prev {
        &:hover {
          background: #c9c5ed;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .p-datepicker-next {
        &:hover {
          background: #c9c5ed;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .p-datepicker-calendar-container {
    .p-datepicker-calendar {
      margin: 0;
      font-size: 14px;
      thead {
        tr {
          th {
            text-align: center;
            font-weight: 500;
            color: $primary;
            text-transform: uppercase;
            padding: 10px;
            border: 1px solid $bgPrimaryLight;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0;
            border: 1px solid $bgPrimaryLight;
            span {
              width: auto;
              height: auto;
              padding: 10px;
              border-radius: 0;
              border-radius: 5px;
              &.p-highlight {
                color: $primary;
                background: $bgPrimaryLight;
                border-radius: 0;
              }
              &:focus {
                box-shadow: none;
              }
            }
            &.p-datepicker-today {
              span {
                background: $primary;
                color: $textWhite;
                &:hover {
                  color: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
  .p-yearpicker {
    .p-yearpicker-year {
      &.p-highlight {
        color: $primary;
      }
    }
  }
  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        color: $primary;
      }
    }
  }
}

.p-checkbox {
  .p-checkbox-box {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid $borderColor;
    &.p-highlight {
      background: $primary;
      border-color: $primary;
      &:hover {
        background: $primary !important;
      }
    }
    &.p-focus {
      box-shadow: none !important;
      outline: 0 !important;
      border-color: $primary !important;
    }
  }
}

.p-tag {
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 5px;
  min-width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  &.p-tag-success {
    background-color: #b6f2d8 !important;
    color: #1c8f3c;
  }
  &.p-tag-danger {
    background-color: #ffc3c3 !important;
    color: #c62828;
  }
  &.p-tag-secondary {
    background-color: #d7d7d7 !important;
    color: #7b7b7b;
  }
  &.p-tag-info {
    background-color: #dbf0ff !important;
    color: #0094ff;
  }
  &.p-tag-primary {
    background-color: #ebe9ff !important;
    color: #322972;
  }
  &.p-tag-warning {
    background-color: #fff4d3 !important;
    color: #f3a533;
  }

  &.p-tag-bopp {
    background-color: #fff4d3;
    color: #f3a533;
  }
  &.p-tag-metalic {
    background-color: #dbf0ff;
    color: #0094ff;
  }
  &.p-tag-glitter {
    background-color: #b6f2d8;
    color: #1c8f3c;
  }
  &.p-tag-glitter_with_metalic {
    background-color: #fce7d9;
    color: #ed701e;
  }
  &.p-tag-matt {
    background-color: #ffc3c3;
    color: #c62828;
  }
  &.p-tag-pp_coated {
    background-color: #a8e9ff;
    color: #00698d;
  }
  &.p-tag-primary {
    background-color: #ebe9ff;
    color: #322972;
  }
  &.p-tag-pp_coated {
    background: #d7f5c9;
    color: #2a7208;
  }

  &.bopp_laminated {
    background: #fff4d3;
    color: #f3a533;
  }
  &.metallic {
    background: #dbf0ff;
    color: #0094ff;
  }
  &.pp_coated {
    background: #d7f5c9;
    color: #2a7208;
  }
  &.glitter {
    background: #b6f2d8;
    color: #1c8f3c;
  }
  &.glitter_metallic {
    background: #fce7d9;
    color: #ed701e;
  }
  &.non_laminated {
    background: #ffc3c3;
    color: #c62828;
  }
  &.p-tag-glossy,
  &.glossy {
    background: #ffd5fd;
    color: #b902b2;
  }

  &.bagTag {
    width: 40px;
    min-width: inherit;
    &.p-tag-0 {
      background-color: #fff4d3;
      color: #f3a533;
    }
    &.p-tag-1 {
      background-color: #dbf0ff;
      color: #0094ff;
    }
    &.p-tag-2 {
      background-color: #ebe9ff;
      color: #322972;
    }
    &.p-tag-3 {
      background-color: #b6f2d8;
      color: #1c8f3c;
    }
    &.p-tag-4 {
      background-color: #fce7d9;
      color: #ed701e;
    }
    &.p-tag-5 {
      background-color: #ffc3c3;
      color: #c62828;
    }
    &.p-tag-6 {
      background-color: #f3a533;
      color: #fff;
    }
    &.p-tag-7 {
      background-color: #0094ff;
      color: #fff;
    }
    &.p-tag-8 {
      background-color: #29725c;
      color: #fff;
    }
    &.p-tag-9 {
      background-color: #ed701e;
      color: #fff;
    }
    &.p-tag-10 {
      background-color: #322972;
      color: #fff;
    }
    &.p-tag-11 {
      background-color: #3b00c1;
      color: #fff;
    }
    &.p-tag-default {
      background-color: #d7d7d7;
      color: black;
    }
  }
  &.bagLamTag {
    width: 40px;
    min-width: inherit;
    &.p-tag-0 {
      background-color: #fff4d3;
      color: #f3a533;
    }
    &.p-tag-1 {
      background-color: #dbf0ff;
      color: #0094ff;
    }
    &.p-tag-2 {
      background-color: #ebe9ff;
      color: #322972;
    }
    &.p-tag-3 {
      background-color: #b6f2d8;
      color: #1c8f3c;
    }
    &.p-tag-4 {
      background-color: #fce7d9;
      color: #ed701e;
    }
    &.p-tag-5 {
      background-color: #ffc3c3;
      color: #c62828;
    }
  }

  &.bagTag {
    width: 40px;
    min-width: inherit;
    &.p-tag-bopp-laminated {
      background-color: #fff4d3;
      color: #f3a533;
    }
    &.p-tag-metallic {
      background-color: #dbf0ff;
      color: #0094ff;
    }
    &.p-tag-pp_coated {
      background: #d7f5c9;
      color: #2a7208;
    }
    &.p-tag-glitter {
      background-color: #b6f2d8;
      color: #1c8f3c;
    }
    &.p-tag-glitter_metallic {
      background-color: #fce7d9;
      color: #ed701e;
    }
    &.p-tag-non_laminated {
      background-color: #ffc3c3;
      color: #c62828;
    }
    &.p-tag-flexo_print {
      background-color: #f3a533;
      color: #fff;
    }
    &.p-tag-roto_gravure {
      background-color: #0094ff;
      color: #fff;
    }
    &.p-tag-screen_print_roll {
      background-color: #29725c;
      color: #fff;
    }
    &.p-tag-plain {
      background-color: #ed701e;
      color: #fff;
    }
    &.p-tag-offset {
      background-color: #322972;
      color: #fff;
    }
    &.p-tag-screen_print_bag {
      background-color: #3b00c1;
      color: #fff;
    }
    &.p-tag-default {
      background-color: #d7d7d7;
      color: black;
    }
    &.p-tag-0 {
      background-color: #f3a533;
      color: #fff;
    }
    &.p-tag-1 {
      background-color: #29725c;
      color: #fff;
    }
    &.p-tag-2 {
      background-color: #0094ff;
      color: #fff;
    }
    &.p-tag-3 {
      background-color: #ed701e;
      color: #fff;
    }
    &.p-tag-4 {
      background-color: #322972;
      color: #fff;
    }
    &.p-tag-5 {
      background-color: #3b00c1;
      color: #fff;
    }
    &.p-tag-default {
      background-color: #d7d7d7;
      color: black;
    }
  }
}

.custom_radio_wrappper {
  .p-radiobutton {
    vertical-align: middle;
  }
}

.width_length_Wrapper {
  .custom_radio_wrappper {
    label {
      .main_label {
        min-width: 100px;
        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 10px;
          &.col-auto {
            width: 100%;
          }
        }
      }
      @media (max-width: 991px) {
        .col-auto {
          width: 45%;
          @media (max-width: 767px) {
            width: 80%;
          }
          .p-inputtext {
            max-width: 100%;
            margin-bottom: 5px;
          }
        }
        .col {
          width: 10%;
          @media (max-width: 767px) {
            width: 20%;
          }
        }
      }
      .p-inputtext {
        max-width: 100px;
      }
    }
  }
}

.p-selectbutton {
  border: 1px solid $borderColor;
  padding: 5px;
  border-radius: 4px;
  .p-button {
    padding: 7px 4px;
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: 500;
    border: 0;
    min-width: 35px;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    &:focus {
      box-shadow: none;
    }
    &.p-highlight {
      background: $primary;
      box-shadow: none;
      &:hover {
        background: $primary;
      }
    }
  }
}
.loader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000bd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10001;
  .loader {
    margin: 0 0 20px;
    animation: scale 1s infinite ease;
    opacity: 0.7;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.form_group {
  .p-multiselect {
    .p-multiselect-label-container {
      .p-multiselect-label {
        font-size: 13px;
        padding: 11px 15px !important;
        color: #333;
        &.p-placeholder {
          color: #ababab;
        }
      }
    }
    .p-multiselect-trigger {
      position: relative;
      svg {
        display: none;
      }
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        background: url(../../Images/select-arrow.svg);
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
      }
    }
    &:hover,
    &:focus {
      border-color: $primary;
      box-shadow: none;
    }
    &.p-focus,
    &.p-inputwrapper-focus {
      box-shadow: none !important;
      border-color: $primary;
    }
    &.p-inputwrapper-focus {
      .p-multiselect-trigger {
        &:before {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }
}

.p-multiselect-panel {
  z-index: 10000 !important;
  .p-multiselect-header {
    padding: 10px;
    background: #fff;
    .p-multiselect-filter-container {
      .p-inputtext {
        padding: 6px 15px 6px 40px;
        &:focus {
          border-color: $primary;
          box-shadow: none;
        }
      }
      .p-icon {
        right: inherit;
        left: 15px;
      }
    }
  }
  .p-multiselect-items-wrapper {
    .p-multiselect-items {
      padding: 0;
      .p-multiselect-item {
        padding: 10px;
        &.p-highlight {
          color: $primary;
          background: $bgPrimaryLight;
          &:hover {
            background: $bgPrimaryLight !important;
          }
        }
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background: #f6f5ff !important;
        }
      }
    }
  }
}

.custom_radio_wrappper {
  .p-radiobutton {
    .p-radiobutton-box.p-highlight {
      border-color: $textPrimary;
      background: $bgWhite;

      .p-radiobutton-icon {
        background-color: $textPrimary;
      }
    }
    .p-radiobutton-box.p-highlight:not(.p-disabled) {
      &:hover {
        border-color: $textPrimary;
        background: $bgWhite;
      }
    }
    .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
      &:hover {
        border-color: $textPrimary;
        background: $bgWhite;
      }
    }
    .p-radiobutton-box {
      &.p-focus {
        box-shadow: none;
        border-color: $primary;
      }
    }
  }
}

.copy_input {
  position: relative;
  .p-inputtext {
    background: $bgPrimaryLight;
    border: 1px dashed $primary;
  }
  .btn_transperant {
    position: absolute;
    right: 10px;
    top: 34px;
  }
}

.not_found_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid $borderColor;
  border-radius: 10px;
  height: 100%;
  .not_found_inner {
    text-align: center;
    .not_found_text {
      max-width: 850px;
      margin: -200px auto 0;
    }
  }
}

.access_denied_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid $borderColor;
  border-radius: 10px;
  height: 100%;
  .access_denied_inner {
    text-align: center;
    .access_denied_text {
      max-width: 850px;
      margin: 30px auto 0;
    }
  }
}

.modal_Wrapper.whatsapp_modal {
  max-width: 310px;
  .p-dialog-content {
    padding: 0;
    .whatsapp_popup_wrapper {
      .img_wrapper {
        padding: 10px 20px;
        height: 200px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .dowanload_img {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          position: absolute;
          right: 10px;
          bottom: 10px;
          img {
            width: 22px;
          }
        }
      }
      .whatsapp_pro_title {
        padding: 0 10px 10px;
        border-bottom: 1px solid $borderColor;
      }
      .whatsapp_pro_detail {
        padding: 10px;
        ul {
          margin: 0 0 20px;
          li {
            margin: 0 0 5px;
          }
        }
      }
    }
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $primary;
}

.panding_action_wrap {
  .btn_transperant {
    img {
      filter: inherit;
    }
  }
}

.p-disabled,
.p-component:disabled {
  color: #495057;
  border: 1px solid #d7d7d7b5;
  opacity: 1;
}

.p-disabled,
.p-component:disabled::placeholder {
  color: #495057;
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: inherit;
}

.ifmcontentstoprint {
  width: 100% !important;
  height: 100% !important;
}

.p-column-filter-overlay {
  z-index: 10000 !important;
}

.select_filter {
  height: 32px;
  &:hover {
    border-color: $primary !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.stock_list_Wrap {
  ul {
    li {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
      label {
        width: 50%;
      }
      .input_wrap {
        width: 50%;
      }
    }
  }
}

.p-progressbar {
  height: 6px;
  background: #dcdcdc;
  .p-progressbar-label {
    display: none;
  }
  &.progress_pink {
    .p-progressbar-value {
      background: #dfa8f1;
    }
  }
  &.progress_purple {
    .p-progressbar-value {
      background: #8e5ff5;
    }
  }
  &.progress_purple {
    .p-progressbar-value {
      background: #8e5ff5;
    }
  }
  &.progress_blue {
    .p-progressbar-value {
      background: $primary;
    }
  }
  &.progress_dark_orange {
    .p-progressbar-value {
      background: #ed701e;
    }
  }
  &.progress_orange {
    .p-progressbar-value {
      background: #f5bb95;
    }
  }
  &.progress_light_orange {
    .p-progressbar-value {
      background: #fce7d9;
    }
  }
  &.progress_sky_blue {
    .p-progressbar-value {
      background: #0094ff;
    }
  }
  &.progress_light_blue {
    .p-progressbar-value {
      background: #a8e9ff;
    }
  }
  &.progress_dark_yellow {
    .p-progressbar-value {
      background: #f5b85b;
    }
  }
  &.progress_yellow {
    .p-progressbar-value {
      background: #fbcf4f;
    }
  }
}

.chart_list_wrap {
  ul {
    li {
      margin-top: 20px;
      label {
        margin-bottom: 10px;
        font-weight: bold;
      }
    }
  }
}
.sales_comparison_top {
  > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
}

.chart_title_list {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      display: flex;
      align-items: center;
      padding: 0 10px;
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $primary;
        margin-right: 4px;
        margin-top: 2px;
        &.green {
          background: #58f2b0;
        }
        &.purple {
          background: #dfa8f1;
        }
        &.light_blue {
          background: #9492ff;
        }
        &.yellow {
          background: #fbcf4f;
        }
        &.blue {
          background: $primary;
        }
      }
      label {
        font-size: 12px;
        color: $textLight;
        font-weight: 500;
      }
    }
  }
}

.bg_box {
  border-radius: 4px;
  border: 1px solid $borderColor;
  background: $bgWhite;
  .bg_box_header {
    padding: 14px 15px;
    border-bottom: 1px solid $borderColor;
  }
  .bg_box_inner {
    padding: 20px 15px;
    .sales_new_customer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid $borderColor;
      .sales_new_customer_left {
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .sales_new_customer_right {
        width: calc(100% - 60px);
        padding-left: 15px;
      }
    }
  }
}

.p-button.p-button-secondary:enabled:focus {
  box-shadow: none;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: $primary;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: transparent;
  color: $primary;
  opacity: 1 !important;
}

.p-column-filter-menu-button:hover {
  background: transparent;
  color: $primary;
  opacity: 1 !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: $primary;
}

.cusor-pointer {
  cursor: pointer !important;
}

.width_content {
  width: fit-content;
  flex: 0 0;
}

.button_filter_wrap {
  margin: 0 !important;
}

.p-overlaypanel.filter_overlay_panel {
  max-height: 500px;
}
.show_more_button_wrapper {
  .btn_transperant {
    color: #000;
    text-align: center;
    width: 100%;
    justify-content: center;
    padding: 10px 20px;
    background: #ebe9ff;
    border-radius: 0;
    &:hover {
      background: #ebe9ff !important;
      color: #000;
    }
  }
}
.report_list_wrapper {
  .btn_border {
    width: 100%;
    padding: 13px 20px;
    font-weight: 600;
    justify-content: flex-start;
  }
}

.webcam_Wrapper {
  &.webcam_wrap {
    .capture_img {
      transform: inherit;
    }
    video {
      transform: inherit;
    }
  }
  .capture_img {
    width: 100%;
    // height: 220px !important;
    aspect-ratio: 6/3;
    // object-fit: fill;
    object-fit: contain;
    border: 1px solid #ddd;
    transform: scaleX(-1);
  }
  video {
    // object-fit: fill
    object-fit: contain;
    border: 1px solid #ddd;
    // height: 220px !important;
    aspect-ratio: 6/3;
    width: 100%;
    transform: scaleX(-1);
  }
}

// responoisve css start

@media (max-width: 1600px) {
  .date_select_wrapper .p-calendar .p-datepicker-trigger:after {
    width: 18px;
    height: 18px;
    background-size: 18px;
  }

  .btn_primary {
    padding: 8px 15px;
    font-size: 13px;
    line-height: 16px;
  }
  .btn_border {
    padding: 8px 15px;
    font-size: 13px;
    line-height: 16px;
  }
  .btn_border.icon_btn {
    padding: 8px;
  }
  .btn_border img {
    width: 18px;
  }
  .form_group .input_wrap.small {
    padding: 8px 15px;
  }
  .form_group .input_wrap.search_wrap {
    padding: 8px 15px;
    padding-left: 35px;
  }
  .form_group .input_wrap.search_wrap {
    background-position: left 11px center;
    background-size: 16px;
  }
  .checkbox_wrap label {
    margin-left: 8px !important;
  }
  .p-tag {
    font-size: 13px;
    line-height: 16px;
    min-width: 80px;
  }
  .form_group .p-inputtext {
    font-size: 13px;
  }
  .checkbox_wrap.large label {
    font-size: 16px;
  }
  .date_range_wrapper .date_range_select {
    padding: 9px 12px;
    font-size: 13px;
    min-width: 210px;
  }
  .btn_primary img {
    width: 18px;
    height: 18px;
  }
  .form_group .p-inputtext {
    padding: 8px 10px;
  }
  .p-dropdown .p-dropdown-label {
    padding: 8px 10px !important;
  }
  .p-dropdown .p-dropdown-trigger:before {
    width: 18px;
    height: 18px;
    background-size: 18px;
  }
}

@media (max-width: 1441px) {
  .btn_primary {
    padding: 8px 12px;
  }
  .btn_border {
    padding: 8px 12px;
  }

  .form_group .p-inputtext {
    padding: 8px 10px;
  }
  .form_group > label {
    margin: 0 0 8px;
  }
  .p-dropdown .p-dropdown-label {
    padding: 9px 12px !important;
  }
  .p-dropdown .p-dropdown-trigger:before {
    width: 18px;
    height: 18px;
    background-size: 18px;
    left: 60%;
  }
  .form_group .p-multiselect .p-multiselect-trigger:before {
    width: 18px;
    height: 18px;
    background-size: 18px;
    left: 60%;
  }
  .upload_file_custom label {
    font-size: 14px;
  }
  h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .checkbox_wrap.large label {
    font-size: 14px;
  }
  .upload_file_custom label {
    padding: 20px;
  }
  .btn_primary {
    padding: 7px 12px;
  }
  .date_range_wrapper .date_range_select {
    padding: 9px 10px;
  }
}

@media (max-width: 991px) {
  .btn_primary {
    padding: 7px 10px;
  }
  .btn_border img {
    width: 18px;
    height: 18px;
  }
  .btn_border {
    padding: 7px 10px;
  }
  .btn_primary img {
    width: 18px;
    height: 18px;
  }
  .btn_border.icon_btn img {
    width: 16px;
    height: 16px;
  }
  .btn_border.icon_btn {
    padding: 8px;
  }
  .form_group .input_wrap.search_wrap {
    padding: 7px 12px;
    padding-left: 35px;
  }
  .form_group .input_wrap.search_wrap {
    background-position: left 10px center;
    background-size: 15px;
  }
  .date_range_wrapper .date_range_select {
    padding: 8px 12px;
  }
  .p-overlaypanel.filter_overlay_panel {
    width: 100%;
    max-width: 550px;
  }
  .filter_overlay_panel
    .overlay_bottom_wrap
    .saved_filter_wrap
    ul
    li
    .p-chip
    .p-inputtext {
    width: 180px;
    font-size: 13px;
  }
  .filter_overlay_panel .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 8px 9px;
    font-size: 12px;
  }
  .filter_overlay_panel .p-dropdown-panel .p-dropdown-header {
    padding: 10px 10px;
    background: #fff;
  }
}

@media (max-width: 767px) {
  .p-overlaypanel.filter_overlay_panel {
    max-width: 400px;
  }
  .filter_overlay_panel .button_filter_wrap {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 5px 0;
  }
  .filter_overlay_panel
    .overlay_bottom_wrap
    .saved_filter_wrap
    ul
    li
    .p-chip
    .p-inputtext {
    width: 114px;
  }
  .date_range_overlay {
    max-width: 465px;
  }
  .rdrInputRanges {
    padding: 10px 0 40px !important;
  }
}

@media (max-width: 575px) {
  .p-overlaypanel.filter_overlay_panel .p-overlaypanel-content .overlay_body {
    padding: 10px;
  }
  .p-overlaypanel.filter_overlay_panel
    .p-overlaypanel-content
    .overlay_top_wrap {
    padding: 15px 10px;
  }
  .filter_overlay_panel .overlay_bottom_wrap {
    padding: 10px;
  }
  .filter_overlay_panel
    .overlay_bottom_wrap
    .saved_filter_wrap
    ul
    li
    .p-chip
    .p-inputtext {
    width: 67px;
    padding: 5px;
  }
  .p-overlaypanel.filter_overlay_panel {
    max-width: 280px;
  }
  .filter_overlay_panel .button_filter_wrap .p-button {
    padding: 6px 8px;
    font-size: 12px;
  }
  .filter_overlay_panel
    .overlay_bottom_wrap
    .saved_filter_wrap
    ul
    li
    .p-chip
    .edit_icon {
    width: 25px;
    height: 25px;
  }
  .filter_overlay_panel
    .overlay_bottom_wrap
    .saved_filter_wrap
    ul
    li
    .p-chip
    .btn_transperant
    img {
    width: 16px;
    height: 16px;
  }
  .filter_overlay_panel .overlay_select_filter_row {
    margin-bottom: 5px !important;
  }
  .p-overlaypanel.filter_overlay_panel {
    max-height: 400px;
  }
  .date_range_wrap .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    width: 155px;
  }
  .date_range_wrap
    .rdrDateRangePickerWrapper
    .rdrCalendarWrapper
    .rdrMonths
    .rdrMonth {
    width: 100%;
  }
  .date_range_overlay {
    max-width: 435px;
  }
  .rdrCalendarWrapper {
    width: calc(100% - 155px);
  }
  .rdrDateRangePickerWrapper {
    width: 100%;
  }
  .p-image-action.p-link {
    width: 35px;
  }
  .right_filter_wrapper {
    ul {
      justify-content: flex-start;
      > li {
        flex-grow: 1;
        .filter_btn {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .main_Wrapper {
    padding: 10px;
  }
  .webcam_Wrapper .capture_img {
    // aspect-ratio: inherit;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .webcam_Wrapper video {
    // aspect-ratio: inherit;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .modal_Wrapper.modal_large {
    padding: 0 10px;
  }
  .btn_primary {
    padding: 6px 12px;
    font-size: 13px;
  }
  .btn_border {
    padding: 6px 12px;
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .p-selectbutton .p-button {
    min-width: 30px;
    font-size: 12px;
  }
  .filter_overlay_panel .button_filter_wrap .p-button {
    padding: 6px 6px;
    font-size: 11px;
  }
  .p-overlaypanel.filter_overlay_panel {
    max-width: 250px;
  }
  .filter_overlay_panel .overlay_bottom_wrap .saved_filter_wrap ul li {
    margin: 0 0 8px;
  }
  .filter_overlay_panel
    .overlay_bottom_wrap
    .saved_filter_wrap
    ul
    li
    .p-chip
    .p-inputtext {
    width: 100%;
  }
  .filter_overlay_panel .overlay_bottom_wrap .saved_filter_wrap ul {
    display: block;
  }
  .filter_overlay_panel .overlay_bottom_wrap .saved_filter_wrap ul li .p-chip {
    width: 100%;
  }
}

.dropdown:has(button[disabled]) {
  cursor: not-allowed !important;
}

@media (max-width: 1600px) {
  .form_group
    .p-multiselect
    .p-multiselect-label-container
    .p-multiselect-label {
    padding: 8px 10px !important;
  }
  // .printing_custom_filter {
  //   .p-multiselect-label {
  //     width: 80px;
  //   }
  // }
  .printing_custom_filter
    ul
    li
    .form_group
    .p-multiselect
    .p-multiselect-trigger::before {
    width: 18px;
    height: 18px;
    background-size: 18px;
  }
}

@media (max-width: 1441px) {
  .p-tag.p-component {
    font-size: 12px;
    line-height: 14px;
    min-width: 80px;
    padding: 5px 10px;
  }
  .panding_action_wrap .btn_transperant img {
    width: 22px;
  }
}

@media (max-width: 575px) {
  .p-tag.p-component {
    min-width: 70px;
    padding: 4px 10px;
  }
}

@media (max-width: 480px) {
  .chart_list_wrap ul li {
    margin-top: 15px;
  }
}

.empty_tooltip {
  opacity: 0 !important;
}
.amount_list {
  .form_group {
    display: flex;
    flex-direction: column;
  }
}
.order_amount {
  float: right;
  color: #ff0000;
  .form_group {
    display: flex;
    justify-content: space-between;
    label {
      font-size: 13px;
      margin-bottom: 5px;
    }
    &.total_amount {
      font-size: 16px;
      font-weight: 900;
    }
    span {
      text-align: end;
    }
  }
}
.amount_label {
  color: #ff0000;
}

.whatsapp_dialog {
  .whatsapp_list {
    margin-left: 25px;
    .list_item {
      margin-bottom: 20px;
      .option_title {
        padding-bottom: 10px;
        list-style: disc;
        display: list-item;
      }
      p {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 23px;
      }
    }
  }
}
